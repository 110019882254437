<template>
  <div>
    <div class="go-with-list w">
      <div v-for="item in goWithList" @click="goDownloadPage" :key="item.id" class="go-with-item" @mouseenter="showQRcode" @mouseleave="hideQRcode">
        <div class="qr-code">
          <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626251728619.png">
        </div>
        <div v-if="item.escortFiles.length > 0" class="go-with-item-top" :style="{backgroundImage:'url(' + item.escortFiles[0].url + ')'}">
          <div class="interest">
            <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626062300432.png" alt="">
            <span>{{item.likeNum}}人感兴趣</span>
          </div>
          <img v-if="item.isVideo" src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626682321533.png" alt="" class="video-play-icon">
        </div>
        <!-- 无图片 -->
        <div v-else class="go-with-item-top">
          <div class="interest">
            <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626062300432.png" alt="">
            <span>{{item.likeNum}}人感兴趣</span>
          </div>

        </div>
        <div class="go-with-item-bottom">
          <div class="go-with-item-box">
            <div class="go-with-item-title">{{item.remark}}</div>
            <div class="go-with-item-place-desc">
              <div class="go-with-item-place">
                <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626059882959.png" alt="">
                <span v-if="item.escortEndCities.length>0">{{item.formCity}} — {{item.escortEndCities[0].city}}</span>
              </div>
              <div class="go-with-item-date">
                <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626059854685.png" alt="">
                {{item.timeDesc}}
              </div>
            </div>
            <div class="go-with-item-content">
              <div class="go-with-item-age" v-if="item.topics.length > 0">
                <div v-if="index < 2" v-for="(item, index) in item.topics" class="topic-box">
                  <span>{{item.topicName}}</span>
                </div>
              </div>
              <div v-else></div>
              <div class="go-with-item-price-box">
                <div class="go-with-item-rmb">￥</div>
                <div class="go-with-item-price">{{item.budgetPrice}}</div>
                <div class="go-with-item-text">人均</div>
              </div>
            </div>
            <div class="go-with-item-info">
              <div class="go-with-item-info-left">
                <div class="go-with-item-info-avatar">
                  <img v-if="item.userBaseVo" :src="item.userBaseVo.photo" alt="">
                </div>
                <div class="go-with-item-user-info">
                  <div class="go-with-item-user-info-top">
                    <div v-if="item.userBaseVo" class="go-with-item-user-name">{{item.userBaseVo.name}}</div>
                    <div class="go-with-item-vip">
                      <img v-if="(item.roles & 512) == 512" src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626058199644.png" alt="">
                      <img v-if="(item.roles & 2048) == 2048" src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626688901035.png" alt="">
                    </div>
                  </div>
                  <div class="go-with-item-user-info-bottom">
                    <div class="go-with-item-age" :style="{backgroundColor: item.userBaseVo.sex == 0 ? '#fe2f48': '#26A1FF'}">
                      <img v-if="item.userBaseVo.sex == 0" src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626059579170.png" alt="">
                      <img v-else src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626757608233.png" alt="">
                      <span v-if="item.userBaseVo">{{item.userBaseVo.age}}</span>
                    </div>
                    <div class="go-with-item-user-country" v-if="item.userBaseVo && !item.userBaseVo.hidden">
                      <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626058549786.png" alt="">
                      <span v-if="item.userBaseVo && !item.userBaseVo.hidden">
                        <template v-if="item.userBaseVo.countryNum >= 2">{{item.userBaseVo.countryNum}}国</template>
                        <template v-if="item.userBaseVo.cityNum >= 2">{{item.userBaseVo.cityNum}}城</template>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.newestRolesImage" class="go-with-item-info-right" :style="{backgroundImage: `url(${item.newestRolesImage})`}">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="more w" @click="getMore">
      <div>查看更多</div>
      <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626054989259.png" alt="">
    </div>
  </div>
</template>

<script>
import { goWithList } from "@/http/api";
export default {
  data() {
    return {
      goWithList: [],
      goWithParams: { pageNum: 1, pageSize: 12, sort: 4 },
    };
  },
  created() {
    this.getGoWithList();
  },
  methods: {
    async getGoWithList() {
      const { data } = await goWithList(this.goWithParams);
      if (data.code === 200) {
        data.data.list.forEach((item) => {
          item.formDate = this.formatDate(item.formDate, "y.m.d");
          let nowDate = new Date();
          let time = item.toDate - nowDate.valueOf();
          time = this.formDuring(time);
          item.toDate = this.formatDate(item.toDate, "y.m.d");
          item.time = time;
          // console.log(item.escortFiles[0].name);

          const videoReg = /\w*\.(mp4|rmvb|flv|mpeg|avi)/;
          if (item.escortFiles[0] && videoReg.test(item.escortFiles[0].name)) {
            item.isVideo = true;
          }
          if (
            item.newestRolesImage &&
            item.newestRolesImage.indexOf("http") < 0
          ) {
            item.newestRolesImage = this.$oss + item.newestRolesImage;
          }
          if (item.userBaseVo && item.userBaseVo.photo.indexOf("http") < 0) {
            item.userBaseVo.photo = this.$oss + item.userBaseVo.photo;
          }
          if (item.userBaseVo) {
            if (item.userBaseVo.cityNum < 2 && item.userBaseVo.countryNum < 2) {
              item.userBaseVo.hidden = true;
            }
          }
          if (
            item.escortFiles.length > 0 &&
            item.escortFiles[0].url.indexOf("http") < 0
          ) {
            item.escortFiles[0].url = this.$oss + item.escortFiles[0].url;
          }
        });
        this.goWithList.push(...data.data.list);
      }
    },
    goDownloadPage() {
      this.$router.push("appDownload");
    },
    // 鼠标悬停显示二维码
    showQRcode(event) {
      // event.target.children[0].style.display = "block";
    },
    // 鼠标离开隐藏二维码
    hideQRcode(event) {
      event.target.children[0].style.display = "none";
    },
    getMore() {
      this.goWithParams.pageNum++;
      this.getGoWithList();
    },
    formDuring(mss) {
      var days = parseInt(mss / (1000 * 60 * 60 * 24));
      var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = (mss % (1000 * 60)) / 1000;
      if (days > 0) {
        return days + "天 ";
      } else {
        return hours + "小时 " + minutes + " 分钟 ";
      }
    },
    formatDate(value, type) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      let h2 = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      if (type == "mdhm") {
        return MM + "-" + d + " " + h + ":" + m;
      } else if (type == "ymd") {
        return y + "/" + MM + "/" + d;
      } else if (type == "y.m.d") {
        return y + "." + MM + "." + d;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.go-with-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 152px;

  .go-with-item {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    width: 285px;
    height: 420px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    cursor: pointer;

    .qr-code {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      z-index: 999;
      width: 100%;
      height: 100%;
      img {
        position: absolute;
        top: calc(50% - 93px);
        left: calc(50% - 93px);
        width: 186px;
        height: 186px;
      }
    }
    .go-with-item-top {
      position: relative;
      width: 285px;
      height: 252px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      .video-play-icon {
        position: absolute;
        left: 0;
        bottom: 0;
      }

      .interest {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        width: 92px;
        height: 28px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 0px 18px 0px 18px;
        span {
          position: absolute;
          top: 3px;
          left: 0;
          transform: scale(0.5);
          white-space: nowrap;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
        img {
          margin-left: 10px;
        }
      }
    }
    .go-with-item-bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 168px;
      .go-with-item-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 257px;
        height: 140px;
        .go-with-item-title {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #555555;
          line-height: 18px;
        }
        .go-with-item-place-desc {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 28px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8f8f8f;
          .go-with-item-place {
            display: flex;
            align-items: center;
            img {
              margin-right: 4px;
              vertical-align: middle;
            }
          }
          .go-with-item-date {
            display: flex;
            align-items: center;
            img {
              margin-right: 4px;
              vertical-align: middle;
            }
          }
        }

        .go-with-item-content {
          display: flex;
          justify-content: space-between;
          .go-with-item-age {
            display: flex;
            justify-content: center;
            align-items: center;

            .topic-box {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 6px;
              width: 72px;
              height: 18px;
              background: #e5f4ff;
              border-radius: 9px;
              white-space: nowrap;
              span {
                display: block;
                transform: scale(0.5);
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #26a1ff;
              }
            }
          }
          .go-with-item-price-box {
            display: flex;
            height: 16px;
            .go-with-item-rmb {
              transform: scale(0.5);
              font-size: 20px;
              font-family: PingFang-SC-Heavy, PingFang-SC;
              font-weight: 800;
              color: #f66725;
            }
            .go-with-item-price {
              font-size: 16px;
              font-family: PingFang-SC-Heavy, PingFang-SC;
              font-weight: 800;
              color: #f66725;
            }
            .go-with-item-text {
              transform: scale(0.6);
              font-size: 20px;
              margin-top: 4px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #898989;
              white-space: nowrap;
            }
          }
        }
        .go-with-item-info {
          display: flex;
          justify-content: space-between;
          .go-with-item-info-left {
            display: flex;
            justify-content: space-between;
            .go-with-item-info-avatar {
              overflow: hidden;
              margin-right: 6px;
              width: 28px;
              height: 28px;
              border-radius: 50%;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .go-with-item-user-info {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .go-with-item-user-info-top {
                display: flex;
                .go-with-item-user-name {
                  font-size: 10px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #e81f1f;
                }
                .go-with-item-vip {
                  margin-left: 4px;
                }
              }
              .go-with-item-user-info-bottom {
                display: flex;

                .go-with-item-age {
                  display: flex;
                  align-items: center;
                  position: relative;
                  margin-right: 3px;
                  width: 24px;
                  height: 12px;
                  background: #fe2f48;
                  border-radius: 6px;
                  img {
                    margin-left: 4px;
                  }
                  span {
                    position: absolute;
                    top: -2px;
                    right: 0;
                    transform: scale(0.5);
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                  }
                }
                .go-with-item-user-country {
                  display: flex;
                  align-items: center;
                  position: relative;
                  vertical-align: middle;
                  width: 44px;
                  height: 12px;
                  background: #dbe8ff;
                  border-radius: 7px;
                  img {
                    position: absolute;
                    top: -2.5px;
                    left: -2.5px;
                  }
                  span {
                    position: absolute;
                    top: -1.5px;
                    right: 6px;
                    width: 32px;
                    white-space: nowrap;
                    transform: scale(0.5);
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #5f64b0;
                  }
                }
              }
            }
          }
          .go-with-item-info-right {
            width: 28px;
            height: 28px;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
.more {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
  font-size: 28px;
  font-family: PingFang-SC-Heavy, PingFang-SC;
  font-weight: 800;
  color: #26a1ff;
  cursor: pointer;

  img {
    margin-left: 6px;
    width: 18px;
    height: 18px;
  }
}
</style>